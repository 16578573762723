import { Box, Flex, Heading } from '@chakra-ui/core';
import { graphql } from 'gatsby';
import React from 'react';
import { BlogPreviewPanel } from '../components/BlogPreviewPanel';
import { FeatureImageHeader } from '../components/FeatureImageHeader';
import { PageLayout } from '../components/pageLayout';
import { Container } from '../components/Container';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/core';

interface Props {}

export const query = graphql`
	query MyQuery {
		prismic {
			allSeason_calendars {
				edges {
					node {
						hero_image
						subtitle
						title
						produce {
							body_text
							feature_photo
							name
							season
						}
					}
				}
			}
		}
	}
`;

const seasonCalendar = ({ data }: Props) => {
	const prismicContent = data.prismic!.allSeason_calendars.edges[0]!.node;

	let produce = prismicContent.produce;

	let transformedProduce = produce.map((node) => ({
		season: node.season,
		bodyPreview: node.body_text[0].text,
		img: node.feature_photo!.url,
		title: node.name,
	}));
	let Summer = transformedProduce.filter((el) => el.season == 'Summer');
	let Autumn = transformedProduce.filter((el) => el.season == 'Autumn');
	let Winter = transformedProduce.filter((el) => el.season == 'Winter ');
	let Spring = transformedProduce.filter((el) => el.season == 'Spring');
	let YearRound = transformedProduce.filter((el) => el.season == 'Year-Round');

	// Get's current season in southern hemisphere
	const getSeason = () =>
		['Summer', 'Autumn', 'Winter', 'Spring'][
			Math.floor((new Date().getMonth() / 12) * 4) % 4
		];

	// Find current produce, (join current season with year round produce)
	let currentProduce = () => {
		let current = getSeason();
		if (current === 'Summer') return YearRound.concat(Summer);
		if (current === 'Autumn') return YearRound.concat(Autumn);
		if (current === 'Winter') return YearRound.concat(Winter);
		if (current === 'Spring') return YearRound.concat(Spring);
	};

	console.log(getSeason());

	console.log(Summer, YearRound);

	return (
		<PageLayout>
			<Flex flexDir="column">
				<Box w="100">
					<FeatureImageHeader
						title={prismicContent.title[0].text || 'Season Calendar'}
						bodyText={prismicContent.subtitle[0].text}
						featureImageUrl={prismicContent.hero_image.url}
					/>
					<Container>
						<Heading size="xl" mr="auto" mb={4}>
							In Season Now
						</Heading>
						<BlogPreviewPanel posts={currentProduce()} justifyContent="start" />
					</Container>
					<Container my={{ base: 10, md: 20 }}>
						<Heading size="xl" mr="auto" mb={8}>
							Calendar
						</Heading>
						<Tabs
							orientation="vertical"
							w="100%"
							variant="soft-rounded"
							variantColor="gray"
						>
							<Flex flexDir="row" justifyContent="start">
								<TabList px={2} pr={8} mr={8} borderRight="1px solid #E2E8F0">
									<Tab>Summer</Tab>
									<Tab>Autumn</Tab>
									<Tab>Winter</Tab>
									<Tab>Spring</Tab>
									<Tab minW="100px">Year Round</Tab>
								</TabList>

								<TabPanels minH="384px">
									<TabPanel>
										<BlogPreviewPanel posts={Summer} />
									</TabPanel>
									<TabPanel>
										<BlogPreviewPanel posts={Autumn} />
									</TabPanel>
									<TabPanel>
										<BlogPreviewPanel posts={Winter} />
									</TabPanel>
									<TabPanel>
										<BlogPreviewPanel posts={Spring} />
									</TabPanel>
									<TabPanel>
										<BlogPreviewPanel posts={YearRound} />
									</TabPanel>
								</TabPanels>
							</Flex>
						</Tabs>
					</Container>
				</Box>
			</Flex>
		</PageLayout>
	);
};

export default seasonCalendar;
